import { Deferred } from "@/utils/bouncer";
import type { ModelBaseDef } from "@/utils/defs";
import { Patcher, ProductPatcher } from "@/utils/patcher";
import type { MiniProduct } from "@paparazzi/types/paparazzi.api.serializers";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import debug from "debug";
import { defineStore } from "pinia";
import sortBy from "sort-by";
import { computed, ref, watch, type Ref } from "vue";
import type { Category, Product, Promotion } from "./defs/shop_defs";
import { typeOptions } from "./defs/shop_defs";
import { useFirebaseStore } from "./firebase";
import { useSSRStore } from "./ssr";
import { useTimesyncStore } from "./timesync";
import { useUserStore } from "./user";

const VERSION = 2;
const log = debug("shop");

export const useShopStore = defineStore("shop", () => {
  const uncachedDeferred = new Deferred();
  const readyDeferred = new Deferred();
  const ssr = useSSRStore();
  const furnace = useFirebaseStore();
  const user = useUserStore();
  const timesync = useTimesyncStore();
  const storage = useLocalStorageStore();
  const status = ref<string>("");
  const error = ref<string>("");
  const objects: {
    products: Ref<Product[]>;
    categories: Ref<Category[]>;
    promos: Ref<Promotion[]>;
  } = {
    products: ref<Product[]>([]),
    categories: ref<Category[]>([]),
    promos: ref<Promotion[]>([]),
  };
  const now = new Date().toISOString();
  const lastUpdate = ref<Record<string, string>>({
    products: now,
    categories: now,
    promos: now,
  });
  const percents = {
    products: ref(0),
    categories: ref(0),
    promos: ref(0),
  };
  const orderMax = ref(user.props.order_max?.max || 50);

  const isReady = computed(() => {
    return percents.products.value >= 1 && percents.categories.value >= 1;
  });
  const allowedCategories = computed(() => {
    let groups = user.props.groups || [];
    const retval = objects.categories.value.filter((cat: Category) => {
      return (
        cat.active &&
        cat.indexed &&
        (cat.allow_all ||
          cat.allowed_groups?.length === 0 ||
          cat.allowed_groups.some((g) => groups.indexOf(g) > -1))
      );
    });
    retval.sort(sortBy("order"));
    return retval;
  });
  const indexedCategories = computed(() => {
    return allowedCategories.value.filter(
      (c: Category) => c.indexed === undefined || c.indexed === true,
    );
  });
  const staticCategories = computed(() => {
    return objects.categories.value.filter((category: Category) => {
      return category.name !== "Children" && category.indexed === false;
    });
  });
  const staticCategoriesId = computed(() => {
    return staticCategories.value.map((c: Category) => c.id);
  });
  const ancestors = computed<{ [key: string]: Category[] }>(() => {
    const retval: { [key: string]: Category[] } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = getAncestors(category);
    }
    return retval;
  });
  const descendents = computed<{ [key: string]: Category[] }>(() => {
    const retval: { [key: string]: Category[] } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = getDescendents([category]);
    }
    return retval;
  });
  const counts = computed<{ [key: string]: number }>(() => {
    const retval: { [key: string]: number } = {};
    for (const category of objects.categories.value) {
      retval[category.id] = allowedProducts.value.filter((p) =>
        p.categories.some((cid) =>
          descendents.value[category.id].map((c) => c.id).includes(cid),
        ),
      ).length;
    }
    return retval;
  });
  const allowedProducts = computed(() => {
    return objects.products.value.filter((product: Product) => {
      return (
        !product.parent &&
        allowedCategories.value.some((c: Category) =>
          product.categories.includes(c.id),
        )
      );
    });
  });
  const indexedProducts = computed(() => {
    return objects.products.value.filter((product: Product) => {
      return (
        indexedCategories.value.findIndex(
          (c: Category) => c.id === product.category,
        ) > -1
      );
    });
  });
  const metalParent = computed((): Category | undefined => {
    const cat = objects.categories.value.find(
      (c) => !c.parent && c.name === "Metal",
    );
    return cat;
  });
  const colorParent = computed((): Category | undefined => {
    const cat = objects.categories.value.find(
      (c) => !c.parent && c.name === "Color",
    );
    return cat;
  });
  const collections = computed((): Category[] => {
    return objects.categories.value.filter((c) => /Collection$/.test(c.name));
  });
  const metals = computed(() => {
    const parentId = metalParent.value?.id || -1;
    return objects.categories.value.filter((c) => c.parent === parentId);
  });
  const colors = computed(() => {
    const parentId = colorParent.value?.id || -1;
    return objects.categories.value.filter((c) => c.parent === parentId);
  });

  const patchers: { [key: string]: Patcher<ModelBaseDef> } = {
    products: new ProductPatcher(
      "products",
      objects.products,
      percents.products,
      furnace.mirrors.find((m) => m.name === "products"),
    ),
    categories: new Patcher(
      "categories",
      objects.categories,
      percents.categories,
      furnace.mirrors.find((m) => m.name === "categories"),
      ["order", "name"],
    ),
    promos: new Patcher(
      "promos",
      objects.promos,
      percents.promos,
      furnace.mirrors.find((m) => m.name === "promos"),
    ),
  };

  // watch for unreleased, timed products
  // after release, set deployed date to the release date so items rise to the top
  watch(
    () => timesync.now,
    () => {
      const notReleased = objects.products.value.filter(
        (p) =>
          p.active && p.release_date && p.deployed < new Date(p.release_date),
      );
      let changed = 0;
      for (const product of notReleased) {
        const deployed = new Date(product.release_date);
        const offset = new Date(deployed.getTime() - 1000 * 60 * 5);
        if (offset.getTime() <= timesync.now) {
          product.deployed = deployed;
          changed += 1;
        }
      }
      if (changed > 0) {
        objects.products.value = objects.products.value;
        lastUpdate.value.product = new Date().toISOString();
      }
    },
  );

  watch(isReady, () => {
    if (isReady) {
      readyDeferred.resolve();
    }
  });
  watch(
    () => user.props.order_max,
    () => {
      orderMax.value = user.props.order_max?.max || 50;
    },
  );
  watch(percents.products, (v) => {
    if (v >= 1) {
      ssr.provides("products");
    }
  });
  watch(percents.categories, () => {
    ssr.provides("categories");
  });
  watch(percents.promos, () => {
    ssr.provides("promos");
  });

  watch(
    () => furnace.mirrors.find((m) => m.name === "products-index")?.data,
    async (value) => {
      if (value) {
        await patchers.products._cachePromise;

        for (const key in value) {
          const existing = objects.products.value.find(
            (p: ModelBaseDef) => p.slug === key,
          );
          if (value[key] && existing) {
            if (existing.stock !== value[key]) {
              existing.stock = value[key];
            }
          } else {
            patchers.products.patch(key, { timestamp: new Date() });
          }
        }
      }
    },
    { deep: true },
  );
  watch(
    () => furnace.mirrors.find((m) => m.name === "products")?.data,
    (value) => {
      if (value) {
        for (const key in value) {
          patchers.products.patch(key, { timestamp: new Date(value[key]) });
        }
      }
    },
    { deep: true },
  );
  watch(
    () => furnace.mirrors.find((m) => m.name === "categories")?.data,
    (value) => {
      if (value) {
        for (const key in value) {
          patchers.categories.patch(key, { timestamp: new Date(value[key]) });
        }
      }
    },
    { deep: true },
  );
  watch(
    () => furnace.mirrors.find((m) => m.name === "promos")?.data,
    (value) => {
      if (value) {
        for (const key in value) {
          patchers.promos.patch(key, { timestamp: new Date(value[key]) });
        }
      }
    },
    { deep: true },
  );

  function ready(): Promise<void> {
    return readyDeferred.promise;
  }

  function getPrice(product: Product): number {
    if (user.isRep && product.prices?.wholesale) {
      return product.prices.wholesale;
    }
    return product.prices?.retail || product.prices?.null || 0;
  }

  function getProductNameWithoutColor(product: string): string {
    return product.split(" - ")[0];
  }

  function getProductColors(product: Product) {
    const matches = objects.categories.value.filter((c) =>
      (product.categories || []).includes(c.id),
    );
    const colors = matches.filter((c) => c.parent === colorParent.value?.id);
    const metals = matches.filter((c) => c.parent === metalParent.value?.id);
    return {
      colors,
      metals,
      all: [...colors, ...metals],
    };
  }

  function getProductColorNames(product: MiniProduct | Product): string[] {
    let item = objects.products.value.find((p) => p.id === product.id);
    if (item) {
      return getProductColors(item).all.map((c) =>
        c.name.replace(" Accessories", ""),
      );
    }
    return [];
  }

  function getProductType(product: MiniProduct | Product): string {
    let item = objects.products.value.find((p) => p.id === product.id);
    if (item) {
      const matches = objects.categories.value.filter(
        (c) =>
          (item.categories || []).includes(c.id) &&
          typeOptions.includes(c.name),
      );
      return matches.map((c) => c.name).join(", ") || "Other";
    }
    return "Other";
  }

  function getAncestors(category: Category): Category[] {
    const parent = objects.categories.value.find(
      (c) => c.id === category.parent,
    );
    if (parent) {
      return [...getAncestors(parent), category];
    }
    return [category];
  }

  function getDescendents(categories: Category[]): Category[] {
    const pids = categories.map((c) => c.id);
    const parents = objects.categories.value.filter((c) =>
      pids.includes(c.parent),
    );
    if (parents.length > 0) {
      return [...getDescendents(parents), ...categories];
    }
    return [...categories];
  }

  async function setup() {
    log("setting up shop...");
    const promises: Promise<any>[] = [];
    for (const patcher of Object.values(patchers)) {
      promises.push(patcher.loadCache());
    }
    await Promise.all(promises);
    uncachedDeferred.resolve();
  }

  return {
    promises: {
      uncached: uncachedDeferred.promise,
    },
    objects,
    percents,
    products: allowedProducts,
    categories: allowedCategories,
    patchers,
    indexedProducts,
    indexedCategories,
    staticCategories,
    staticCategoriesId,
    promos: objects.promos,
    orderMax,
    metalParent,
    colorParent,
    collections,
    metals,
    colors,
    ancestors,
    descendents,
    counts,
    isReady,
    lastUpdate,
    setup,
    ready,
    getPrice,
    getProductNameWithoutColor,
    getProductColors,
    getProductColorNames,
    getProductType,
  };
});
