import type { Database, Query } from "firebase/database";

import { cancelDebounce, debounce, Deferred } from "@/utils/bouncer";
import type { FirebaseProfile } from "@/utils/defs";
import api from "@virgodev/bazaar/functions/api";
import copy from "@virgodev/bazaar/functions/copy";
import { useLocalStorageStore } from "@virgodev/bazaar/functions/localstorage/store";
import debug from "debug";
import * as firebase from "firebase/app";
import {
  getAuth,
  signInWithCustomToken,
  signOut,
  type Auth,
} from "firebase/auth";
import {
  ref as fireRef,
  getDatabase,
  limitToLast,
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  onValue,
  query,
  set,
} from "firebase/database";
import { defineStore } from "pinia";
import { computed, ref, watch, type Ref } from "vue";
import type { Mirror } from "./defs/shop_defs";
import { useUserStore } from "./user";
import { acquireLock, releaseSemaphore } from "./utils/firebase_lock";

const VERSION = 2;
const FIREBASE_PROFILE_VERSION = 3;
const log = debug("firebase");
const firebaseCredentials = {
  apiKey: "AIzaSyBqv5KUhbPS2iKlb2tIUv7NZh8kfZ54H9k",
  authDomain: "paparazzi-31b33.firebaseapp.com",
  databaseURL: "https://paparazzi-items.firebaseio.com",
  projectId: "paparazzi-31b33",
  storageBucket: "paparazzi-31b33.appspot.com",
  messagingSenderId: "294210058109",
  appId: "1:294210058109:web:b8f495db46ea19b87abe14",
};

const clientId = computed(() => {
  // if (!localStorage.clientid) {
  //   localStorage.clientid = "_" + Math.random().toString(36).slice(2, 20);
  // }
  return "_" + Math.random().toString(36).slice(2, 20); //localStorage.clientid;
});

function generateEmptyProfile(): FirebaseProfile {
  return {
    timestamp: Date.now(),
    version: FIREBASE_PROFILE_VERSION,
    token: "",
    db: null,
  };
}

export const useFirebaseStore = defineStore("firebase", () => {
  let itemDb = "n3";
  let frenzyVersion = "p";
  if (import.meta.env.NODE_ENV === "test") {
    itemDb = "t3";
    frenzyVersion = "t";
  } else if (import.meta.env.VITE_APP_TEST) {
    itemDb = "d3";
    frenzyVersion = "d";
  }

  let fireApp: firebase.FirebaseApp | null = null;
  const loginDeferred = new Deferred();
  const fireItemsApp = firebase.initializeApp(firebaseCredentials, "items");
  const user = useUserStore();
  const storage = useLocalStorageStore();

  const initUserId = ref(undefined as number | undefined);
  const status = ref<string>("");
  const error = ref<string>("");
  const profile = ref<FirebaseProfile>(generateEmptyProfile());
  const mirrors: Ref<Mirror[]> = ref<Mirror[]>([
    { name: "user", url: "", data: {}, useValue: true },
    { name: "frenzy", url: `fz/${frenzyVersion}`, data: false, useValue: true },
    {
      name: "products-index",
      url: `product-index/${itemDb[0]}/`,
      data: {},
      limit: 50,
    },
    { name: "products", url: `products/${itemDb[0]}/`, data: {} },
    { name: "categories", url: `category-index/${itemDb[0]}/`, data: {} },
    { name: "promos", url: `promo-index/${itemDb[0]}/`, data: {} },
  ]);
  const outgoing = ref({} as { [index: string]: any });
  const outgoingTs = ref({} as { [index: string]: string });
  const auth = ref(null as Auth | null);

  const userData = computed(() => {
    return mirrors.value.find((m) => m.name === "user")?.data;
  });
  const mirror = computed(() => {
    return mirrors.value.reduce((a: { [key: string]: any }, b) => {
      a[b.name] = b.data;
      return a;
    }, {});
  });

  watch(
    () => user.props.id,
    () => getFirebaseToken(),
  );

  watch(
    () => profile.value.token,
    () => firebaseInit(user.props.id),
  );

  watch(userData, () => {
    if (userData.value?.groups && user.props.groups != userData.value.groups) {
      log("updating groups", userData.value.groups);
      user.props.groups = userData.value.groups;
    }
  });

  async function getFirebaseToken() {
    if (user.props.id) {
      if (await debounce("getFirebaseToken")) {
        const saved = storage.get(
          "firebase_profile",
          null,
        ) as FirebaseProfile | null;
        if (saved) {
          const notExpired = saved.timestamp > Date.now() - 1000 * 60 * 50;
          const notOld = saved.version >= FIREBASE_PROFILE_VERSION;
          const isCurrentUser = user.props.id === saved.user_id;
          if (notExpired && notOld && isCurrentUser) {
            profile.value = saved;
            log("loaded profile from cache", saved, user.props.id);
          } else {
            log(
              "ignored profile in cache",
              copy(saved),
              `${user.props.id} ?= ${profile.value.user_id}`,
              notExpired,
              notOld,
              isCurrentUser,
            );
            storage.put("firebase_profile", null);
          }
        }
        if (!profile.value.user_id || !profile.value.db) {
          const response = await api({
            url: "profile/firebase/",
            method: "POST",
          });
          if (response.ok) {
            profile.value = response.body as FirebaseProfile;
            profile.value.timestamp = Date.now();
            profile.value.version = FIREBASE_PROFILE_VERSION;
            profile.value.user_id = user.props.id;
            if (profile.value.db) {
              storage.put("firebase_profile", profile.value);
            }
            log("loaded profile from api");
          } else {
            throw new Error("Firebase profile not found");
          }
        }
      }
    } else {
      cancelDebounce("getFirebaseToken");
      profile.value = generateEmptyProfile();
      log("using anon profile");
    }
  }

  async function firebaseInit(userId?: number, attempts = 0) {
    // don't attempt to login if already logged or logging
    if (!status.value || initUserId.value !== userId) {
      initUserId.value = userId;

      // we use this to debug some problems on logrocket
      log("init firebase", user.props.id);
      status.value = "loading";

      // the firebase db is sharded
      const { db, token } = profile.value;
      log("using", db, token ? token.slice(-10, -1) : "");

      // dispose of previous app
      if (fireApp) {
        log("deleting previous app");
        firebase.deleteApp(fireApp);
      }
      if (auth.value) {
        loginDeferred.reset();
      }
      auth.value = null;

      // db for user and cart
      if (db) {
        const creds = copy(firebaseCredentials);
        log("starting firebase connection", creds);
        creds.databaseURL = db;
        fireApp = firebase.initializeApp(creds);
      }

      // firebase login
      if (fireApp && token) {
        log("signing in with firebase", token.slice(-10, -1));
        try {
          // verify login works or try again
          auth.value = await getAuth(fireApp);
          await signInWithCustomToken(auth.value, token);
          log("auth", auth.value.currentUser?.uid);
          if (auth.value?.currentUser?.uid) {
            loginDeferred.resolve();
            const userMirror = mirrors.value.find((m) => m.name === "user");
            if (userMirror) {
              userMirror.url = `users/${auth.value.currentUser.uid}`;
              const dbInstance = getDatabase(fireApp);
              addValueMirror(dbInstance, userMirror);
            }
          }
        } catch (ex) {
          log("login failed", attempts);
          if (attempts === 0) {
            // clear firebase profile
            storage.put("firebase_profile", null);
            profile.value = generateEmptyProfile();
          } else {
            throw new Error(`Firebase login failed: ${ex}`);
          }
        }
      }
      log("firebase initialized");
    } else {
      log("firebase already initialized or initializing");
    }
  }

  async function firebaseSyncProducts() {
    try {
      error.value = "";
      const itemsFire = getDatabase(fireItemsApp);

      const itemMirrors = mirrors.value.filter((m) => m.name !== "user");
      for (const mirror of itemMirrors) {
        if (mirror.useValue) {
          if (mirror.url) {
            addValueMirror(itemsFire, mirror);
          }
        } else {
          addMirror(itemsFire, mirror);
        }
      }
    } catch (ex) {
      console.warn("firebase failure", ex);
      if (ex) {
        error.value = ex.toString();
      } else {
        error.value = "Unknown error";
      }
    }
  }

  function addMirror(db: Database, mirror: Mirror) {
    let dbref: Query = fireRef(db, mirror.url);

    if (mirror.limit && mirror.limit > 0) {
      dbref = query(dbref, limitToLast(mirror.limit));
    }

    log(`sycing ${mirror.name}`);

    // listen for updates
    onChildAdded(dbref, async (update) => {
      // log("added", mirror.url, update.key, update.val());
      if (update.key) {
        const val = update.val();
        mirror.data[update.key] = val;
      }
    });
    onChildRemoved(dbref, async (update) => {
      log("removed", mirror.url, update.key, update.val());
      if (update.key) {
        if (mirror.data[update.key]) {
          delete mirror.data[update.key];
        }
      }
    });
    onChildChanged(dbref, async (update) => {
      log("changed", mirror.url, update.key, update.val());
      if (update.key) {
        mirror.data[update.key] = update.val();
      }
    });
  }

  function addValueMirror(db: Database, mirror: Mirror) {
    log(`syncing value ${mirror.name}`);

    if (!mirror.userRequired || auth.value?.currentUser) {
      onValue(fireRef(db, mirror.url), (update) => {
        mirror.data = update.val();
      });
    }
  }

  function shouldUpdate(key: string, lastTimestamp?: null | string) {
    if (
      userData.value &&
      userData.value.timestamps &&
      userData.value.timestamps[key]
    ) {
      if (lastTimestamp) {
        return (
          new Date(userData.value.timestamps[key]) > new Date(lastTimestamp)
        );
      }
      return true;
    }
    return false;
  }

  async function update(key: string, dt: string, value: any) {
    if (fireApp && user.isAuthenticated && auth.value?.currentUser) {
      outgoingTs.value[key] = dt;
      outgoing.value[key] = value;

      let currentVersion = VERSION;
      try {
        currentVersion = userData.value.stamp[1];
      } catch (e) {}

      if (
        Object.keys(outgoing).length > 0 &&
        currentVersion <= VERSION &&
        (await debounce("update-firebase"))
      ) {
        const updates = copy(outgoing.value);
        const timestamps = {
          ...(userData.value.timestamps ?? {}),
          ...copy(outgoingTs.value),
        };
        outgoing.value = {};

        const now = Date.now();
        const retval = {
          ...userData.value,
          timestamp: now,
          stamp: [now, VERSION, clientId.value],
          timestamps,
          ...updates,
        } as {
          [key: string]: any;
        };
        for (const key in updates) {
          log(`${key} >> firebase`, retval.timestamp);
        }

        const userFire = getDatabase(fireApp);
        const dbref = fireRef(userFire, `users/${auth.value.currentUser.uid}`);
        set(dbref, retval);
      }
    }
  }

  async function lock(key: string, func: () => Promise<any>): Promise<any> {
    if (!fireApp || !auth.value?.currentUser) {
      log("Not logged in, cannot get lock");
      return await func();
    } else {
      return acquireLock(
        getDatabase(fireApp),
        auth.value,
        key,
        clientId.value,
        func,
      );
    }
  }

  async function dropLock(key: string) {
    if (fireApp && auth.value) {
      const itemsFire = getDatabase(fireApp);
      return releaseSemaphore(itemsFire, auth.value, key, clientId.value);
    } else {
      console.error("");
    }
  }

  async function clear() {
    if (auth.value) {
      await signOut(auth.value);
      auth.value = null;
      for (const mirror of mirrors.value) {
        if (mirror.useValue && mirror.url) {
          mirror.data = undefined;
        }
      }
    }
    await storage.put("firebase_profile", null);
  }

  async function setup() {
    if (user.props.id) {
      getFirebaseToken();
      firebaseInit();
    }
    // firebaseLoadCache();
    getFirebaseToken();
    firebaseSyncProducts();
  }

  return {
    promises: {
      login: loginDeferred.promise,
    },
    mirror,
    mirrors,
    clientId,
    userData,
    shouldUpdate,
    update,
    lock,
    dropLock,
    clear,
    error,
    setup,
  };
});
